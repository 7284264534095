<template>
  <div>
    <!--begin::Dashboard-->

    <h4>Selecione outra filial abaixo:</h4>
    <button @click="showModalFilial" class="btn btn-warning font-weight-bold">
      <i class="mr-2 fas fa-home"></i>{{ filialSelecionada }}
    </button>
    <hr />

    <div class="row">
      <div
        v-for="(item, index) in dashbords"
        :key="index"
        v-show="lista_permissoes_filial[item.permissao]"
        class="col-xxl-4 mt-5"
      >
        <a
          @click="trocar_pagina(item.rota)"
          class="card card-custom wave"
          :class="item.wave"
        >
          <div class="card-body">
            <div class="d-flex align-items-cente">
              <div class="d-flex flex-column">
                <a
                  @click="trocar_pagina(item.rota)"
                  class="text-dark text-hover-white font-weight-bold font-size-h4 mb-3"
                >
                  {{ item.title }}
                </a>
                <div class="text-dark-75">
                  {{ item.body }}
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
    <v-divider
      v-if="lista_permissoes_filial['c_Filial']"
      class="mt-5"
    ></v-divider>

    <relatorioGeral />

    <v-col cols="auto">
      <v-dialog
        v-model="modalfilial"
        transition="dialog-bottom-transition"
        max-width="600"
      >
        <template>
          <v-card persistent>
            <v-card height="60px">
              <h1 class="pt-3 pl-3">Escolha uma Filial</h1>
            </v-card>
            <b-table
              class="table table-head-custom table-vertical-center table-head-bg table-borderless"
              :items="lista_filiais_autorizadas"
              show-empty
              empty-html="Nenhuma filial autorizada para seu perfil</p>
              <p>Contate o administrador.</p>"
              :fields="['filial', 'acao']"
            >
              <template #head(acao)=""><span></span></template>
              <template #head(filial)="">
                <div>Sede</div>
              </template>
              <template #cell(acao)="{ item }">
                <div class="text-right w-100">
                  <a
                    href="#"
                    class="btn btn-icon btn-light btn-sm"
                    @click="updateFilial(item.id)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <inline-svg
                        src="media/svg/icons/Navigation/Arrow-right.svg"
                      >
                      </inline-svg>
                    </span>
                  </a>
                </div>
              </template>
            </b-table>
            <!-- <v-card-actions class="justify-end">
                <v-btn text @click="logout">Fechar</v-btn>
              </v-card-actions> -->
          </v-card>
        </template>
      </v-dialog>
    </v-col>

    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import relatorioGeral from "@/view/components/arquivex/relatorioGeral";
export default {
  components: { relatorioGeral },
  data() {
    return {
      modalfilial: false,
      dashbords: [
        {
          rota: "gerenFuncionarios",
          title: "Funcionarios",
          body: "Rota para gerenciamento de funcionarios",
          permissao: "c_Usuario",
          wave: "wave-animate-fast wave-success",
        },
        {
          rota: "arquivex",
          title: "Gerencimento de arquivos",
          body: "Rota para gerenciamento de diretorios",
          permissao: "ra_Pastas",
          wave: "wave-animate-slow wave-primary",
        },
        {
          rota: "relatorios",
          title: "Relatorios de Ações",
          body: "Relatorio de ações dentro do sistema numero e graficos ",
          permissao: "c_Filial",
          wave: "wave-animate-fast wave-danger",
        },
      ],
    };
  },
  computed: {
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    lista_filiais_autorizadas() {
      return Object.values(this.$store.state.sistema.lista_filiais_autorizadas);
    },

    filialSelecionada() {
      let filial_id = this.$store.state.sistema.id_filial_selecionada;
      if (!this.$store.state.sistema.lista_filiais_autorizadas || !filial_id)
        return "Selecionar filial";
      return this.$store.state.sistema.lista_filiais_autorizadas[filial_id]
        .filial;
    },
  },
  name: "dashboard",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  },

  methods: {
    async updateFilial(value) {
      await this.$store.dispatch("sistema/setFilial", value);
      this.$store.dispatch("sistema/listar_permissoes_sede", value);
      //  console.log(value)
      window.location.reload();
    },
    hideModalFilial() {
      this.modalfilial = false;
    },
    showModalFilial() {
      this.modalfilial = true;
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    trocar_pagina(rota) {
      this.$router.push({ name: rota });
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
  },
};
</script>
