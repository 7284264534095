<template>
  <div v-if="relgeral" class="pt-5">
    <v-card  class="text-center pt-2 pb-2 d-flex justify-content-center">
      <h3>Dados Gerais {{ filial.filial }}</h3>
      <div class="ml-2">
    <b-select style="font-size: 15px;" class="form-control" v-model="ano">
      <option value="2022">2022</option>
      <option value="2023">2023</option>
      <option value="2024">2024</option>
      <option value="2025">2025</option>
    </b-select>
  </div>

    </v-card>
    <v-container>
      <v-row>
        <v-col md="6">
          <v-card elevation="10">
            <v-card-text class="text-center">
              <h3>Total de Paginas: {{ relgeral.total_paginas }}</h3>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col md="6">
          <v-card elevation="10">
            <v-card-text class="text-center">
              <h3>
                Totais Arquivos : {{ relgeral.total_registros }}
              </h3></v-card-text
            >
          </v-card>
        </v-col>

        <v-col md="12">
          <v-card elevation="10">
            <v-card-text class="text-center">
              <b-table
                :fields="['Extensao', 'total_paginas', 'total_registros']"
                :items="relgeral.documento_tipo"
                :striped="true"
                :hoverable="true"
              >
                <template #empty="props">
                  <div
                    class="has-text-centered p-3"
                    v-if="props.data.length === 0"
                  >
                    Nenhum dado encontrado.
                  </div>
                </template>
                <template #head(total_paginas)> Quantidade Paginas </template>
                <template #head(total_registros)>
                  Quantidade arquivos
                </template>
                <template #cell(extensao)="{ item }">
                  {{ item.extensao }}
                </template>
                <template #cell(total_paginas)="{ item }">
                  {{ item.total_paginas ? item.total_paginas : "N/A" }}
                </template>
                <template #cell(total_registros)="{ item }">
                  {{ item.total_registros }}
                </template>
              </b-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-divider class="mt-5"></v-divider>

    
    <v-card class="text-center p-1">
      <h3>Grafico Paginas Escaneadas {{ ano }}</h3>
    </v-card>

    <apexchart
      type="bar"
      height="350"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import FilialService from "@/core/services/filial.service";

export default {
  mixins: [fireAlert],

  data() {
    return { ano: 2023,loading:false };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Relatorio Geral" }]);
  },
  watch: {
    ano() {
      this.$store.dispatch("relg/listar_relgeral", this.ano);
    },
  },
  created() {
    this.getrelAll();
  },
  computed: {
    filial() {
      return FilialService.getFiliais()[
        this.$store.state.sistema.id_filial_selecionada
      ];
    },
    relgeral() {
      return this.$store.state.relg.relgeral;
    },

    mensagem_alert() {
      return this.$store.state.configEmpresa.mensagem_alert;
    },

    series() {
      // console.log(this.label)
      // console.log(this.values[0].data)
      return [
        {
          name: "Quantidade",
          data: this.relgeral.paginas_grafico.total_paginas,
        },
      ];
    },

    chartOptions() {
      let data = {
        chart: {
          height: 350,
          type: "bar",
          events: {
            click: function (chart, w, e) {
              // console.log(chart, w, e)
            },
            stacked: true,
          // stackType: '100%',
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        // colors: ['#'],
      plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            barHeight: "100%",
            distributed: true,
            endingShape: "rounded",
            dataLabels: {
              hideOverflowingLabels: true,
              position: "center",
              total: {
                enabled: true,
                style: {
                  fontSize: "10px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        dataLabels: {
          offsetY: 5,
          enabled: true,
          style: {
            fontSize: "12px",
            fontWeight: 900,
          },
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: this.relgeral.paginas_grafico.categories ?? null,
          labels: {
            style: {
              // colors: colors,
              fontSize: "12px",
            },
          },
        },
      };

      return data;
    },
  },
  methods: {
    async getrelAll() {
      await this.$store.dispatch("relg/listar_relgeral", this.ano);
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>